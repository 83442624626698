import React from 'react'
import styled from 'styled-components'
import { PortableText, Typography } from '../utils'

export const TextContainer = ({ heading, body, isImprint = false }) => (
  <Container>
    <HeaderWrapper>
      <Typography variant="h1">{heading}</Typography>
    </HeaderWrapper>
    <div>
      <PortableText blocks={body} className="textBody" />
      {isImprint && (
        <a href="javascript:gaOptout();">Google Tracking deaktivieren</a>
      )}
    </div>
  </Container>
)
export const PageContainer = ({ heading, subheading, children }) => (
  <Container>
    <HeaderWrapper>
      <Typography variant="h1">{heading}</Typography>
      {subheading && <Typography variant="p">{subheading}</Typography>}
    </HeaderWrapper>
    {children}
  </Container>
)

const Container = styled.article`
  padding-bottom: 6rem;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-rows: minmax(16rem, 20rem) auto;
  row-gap: 5rem;
  justify-items: center;

  .textBody {
    width: 90%;
    max-width: 720px;
    font-size: 1.25rem;
    line-height: 1.5;
    p {
      margin-bottom: 1.5rem;
    }
  }

  a,
  a:visited {
    color: ${({ theme }) => theme.color.secondary};
  }

  @media (min-width: 768px) {
    grid-template-rows: 24rem auto;
  }
`
const HeaderWrapper = styled.div`
  background-image: ${({ theme }) =>
    `linear-gradient(280deg, ${theme.color.purple800}, ${theme.color.purple600})`};
  width: 100%;
  text-align: center;
  justify-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 4rem;
  margin: 0 auto;

  p {
    max-width: 512px;
    font-size: 1rem;
  }
`
