import React from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { Typography } from '../utils'

export function Toast({ isVisible, children, icon, style }) {
  return (
    <AnimatePresence>
      {isVisible && (
        <Container
          key="notification"
          style={style}
          initial={{ y: 200, left: '50%', x: '-50%' }}
          animate={{ y: -16, transition: { duration: 1, ease: 'easeOut' } }}
          exit={{ y: 300, transition: { duration: 1, ease: 'easeIn' } }}
          role="dialog"
        >
          {icon && icon}
          <Text variant="p">{children}</Text>
        </Container>
      )}
    </AnimatePresence>
  )
}

const Container = styled(motion.div)`
  z-index: 101;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.028),
    0 6.7px 5.3px rgba(0, 0, 0, 0.04), 0 12.5px 10px rgba(0, 0, 0, 0.05),
    0 22.3px 17.9px rgba(0, 0, 0, 0.06), 0 41.8px 33.4px rgba(0, 0, 0, 0.072),
    0 100px 80px rgba(0, 0, 0, 0.1);
  width: 90%;
  left: calc(50% - (90% / 2));
  height: 50px;

  @media (min-width: 768px) {
    width: 400px;
    left: calc(50% - (400px / 2));
  }
  @media (min-width: 1024px) {
    width: 500px;
    left: calc(50% - (500px / 2));
  }
`

const Text = styled(Typography)`
  font-size: 1rem;
  padding-left: 15px;
  margin-bottom: 0;
`
