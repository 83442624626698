import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Cookie from 'universal-cookie'
import { Mail, XCircle, Send } from 'react-feather'
import { Toast } from '../Toast/Toast'
import { sendFeedback, validateEmail } from '../helpers'
import { Button, Checkbox, Textarea, Input, Typography, Link } from '../utils'
import { FeedbackModal } from './Modal'
import { codes } from './giveawayCodes'

const cookie = new Cookie()

export const FeedbackForm = ({
  shareLink,
  gridColumn,
  hasHeading = false,
  style,
}) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [feedback, setFeedback] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [isError, setError] = useState(false)
  const [buildUrl, setBuildUrl] = useState('')
  const [isModal, toggleModal] = useState(false)

  useEffect(() => {
    if (cookie.get('isCookieAccepted')) {
      return setBuildUrl(cookie.get('buildUrl'))
    }
    return setBuildUrl(shareLink)
  }, [shareLink])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const body = {
      name: name,
      email: email,
      feedback: feedback,
      build: buildUrl,
    }

    const response = await sendFeedback(body)
    if (response) {
      setName('')
      setEmail('')
      setFeedback('')
      setIsChecked(false)
      toggleModal(true)
      setSuccess(true)
      setTimeout(() => setSuccess(false), 3000)
    } else {
      setError(true)
      setTimeout(() => setError(false), 3000)
    }
  }

  const mailIcon = <Mail size={24} style={{ marginLeft: `15px` }} />
  const errorIcon = <XCircle size={24} style={{ marginLeft: `15px` }} />

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        gridColumn={gridColumn}
        aria-label="form"
        style={style}
      >
        {hasHeading && <Heading variant="h2">Gib uns dein Feedback</Heading>}
        <Input
          id="feedback-name"
          name="name"
          placeholder="Dein Name*"
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoComplete="fullname"
          gridColumn="1 / 4"
        />
        <Input
          id="feedback-email"
          name="email"
          placeholder="Deine Email*"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
          validation={validateEmail(email)}
          gridColumn="1 / 4"
        />
        <Textarea
          className="feedback-textarea"
          name="feedback"
          id="feedback-textarea"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Hier ist Platz für Feedback*"
          gridColumn="1 / 4"
        />
        <Checkbox
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
          description={
            <>
              <Link
                to="/privacy-policy"
                color="white"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                Nutzungsbedingungen
              </Link>{' '}
              akzeptieren*
            </>
          }
          name="terms"
        />
        <FeedbackButton
          type="submit"
          value="Submit"
          fullWidth
          disabled={
            name.trim().length >= 3 &&
            email &&
            feedback.trim().length >= 20 &&
            isChecked
              ? false
              : true
          }
        >
          <Send style={{ marginRight: 8 }} />
          Send
        </FeedbackButton>
      </Form>
      <Toast
        isVisible={isSuccess}
        icon={mailIcon}
        style={{
          color: `#012407`,
          background: `#95ffb8`,
        }}
      >
        Dein Feedback wurde versendet
      </Toast>
      <Toast
        isVisible={isError}
        icon={errorIcon}
        style={{
          color: `#88204D`,
          background: `#F3AECC`,
        }}
      >
        Dein Feedback konnte nicht versendet werden
      </Toast>
      {isModal && <FeedbackModal toggleModal={toggleModal} codes={codes} />}
    </>
  )
}

const Form = styled.form`
  align-self: flex-start;
  grid-column: ${({ gridColumn }) => gridColumn};
  margin: 0 1rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(4rem, 1fr));
  align-items: center;
  gap: 1rem;

  @media (min-width: 769px) {
    margin: unset;
  }
  @media (min-width: 1280px) {
    column-gap: 2rem;
  }
`

const Heading = styled(Typography)`
  grid-column: 1 / 4;
`
const FeedbackButton = styled(Button)`
  grid-column: 1 / 4;
  @media (min-width: 425px) {
    grid-column: 3;
  }
`
