export const codes = [
  'K2QCHU',
  'UKVP6G',
  'VKUV5Y',
  'EKLAJW',
  'PUHCN6',
  'LH9BVN',
  'UFCXUX',
  'YDT25F',
  'ULW68K',
  'NKCEFN',
  'HHMWPF',
  'U6LVTF',
  'E5L9R2',
  'WKWN4M',
  'YUC6J9',
  'E59D5W',
  '4LYWJU',
  'TTNQYW',
  'V5BHVP',
  'U3DSJD',
  '7PV6JL',
  'LA2JA9',
  '24RXU2',
  'HR2W9R',
  'NJFEC6',
  'YYKZR5',
  '2N9SX7',
  'F7C44V',
  'SDDUTG',
  'LDVBGA',
  'N992UN',
  '5CEQLM',
  '63RN67',
  '5KU5DJ',
  'ND73RP',
  'UZLBH2',
  'N48YE3',
  '7AWETS',
  'A9KGAR',
  '8ZDCHK',
  'R76BVX',
  'HJC3N5',
  'CC9MRJ',
  '33A4DX',
  'SVSGZD',
  '78Q3FG',
  '9HVG52',
  '9FPBF9',
  '3KUD3X',
  'U7F6SY',
  '3EGSU7',
  'PJ4FCD',
  '7KENJK',
  'CN3K82',
  'KKGXQ4',
  'FK9U6U',
  'V8Q8WY',
  '8R2BPT',
  'NWG6MG',
  'PCCSWQ',
  'QKTX9K',
  'D27WDH',
  'RKCXJ6',
  'GQY29T',
  'BPFZSM',
  'J4FJ2N',
  'AWGZB5',
  '6H8JD7',
  '3XWDGM',
  'ESNWK6',
]
