import React from 'react'
import styled from 'styled-components'
import { IconButton, Typography, Button } from '../utils'
import { X as XIcon } from 'react-feather'

export function FeedbackModal({ toggleModal }) {
  return (
    <Background>
      <Container>
        <IconButton size="small" onClick={() => toggleModal(false)}>
          <XIcon />
        </IconButton>
        <Wrapper>
          <Typography variant="h3">Dein Feedback wurde gesendet.</Typography>
          <Typography variant="h4">Der Code kommt per Email.</Typography>
        </Wrapper>
        <Button
          className="finished-button"
          variant="outlined"
          fullWidth
          onClick={() => toggleModal(false)}
        >
          Fertig
        </Button>
      </Container>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 20;
`
const Container = styled.div`
  z-index: 30;
  position: relative;
  width: 90%;
  max-width: 420px;
  background-color: white;
  border-radius: 8px;
  padding: 1rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  button {
    align-self: flex-end;
  }
  .finished-button {
    margin-top: 1rem;
  }
  color: ${({ theme }) => theme.primary};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h4 {
    margin-bottom: 8px;
  }
`
