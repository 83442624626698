import React from 'react'
import { FeedbackForm } from '../components/Feedback/FeedbackForm'
import { PageContainer } from '../components/layout/TextContainer'
import { Seo } from '../components/utils'

export default function FeedbackPage() {
  return (
    <>
      <Seo title="Feedback" />
      <PageContainer heading="Feedback">
        <FeedbackForm />
      </PageContainer>
    </>
  )
}
